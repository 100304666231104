.ToggleSwitch {
    background: #F6E3D3;
    width: 46px;
    height: 12px;
    margin-top: 5px;
    border-radius: 1em;
    cursor: pointer;
}
.ToggleSwitch::before {
    content: attr('Enable');
}
.ToggleSwitch--label {
    text-align: right;
    font: 13px/18px 'Poppins';
    letter-spacing: 0.97px;
    color: #fff;
}
.ToggleSwitch .knob {
    position: relative;
    width: 24px;
    height: 24px;
    background: #8D8C8A;
    border: 1px solid #8D8C8A;
    border-radius: 50%;
    left: 0em;
    transition: left 0.3s ease-out;
    top: -6px;
}
.ToggleSwitch .knob.active {
    left: 25px;
    background: #EF882E;
    border: 1px solid #EF882E;
}
