@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.mybtn {
    font-family: 'Poppins';
    font-style: normal;
    color: white;
    background: #F8933C;
    border-radius: 40px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    text-transform: capitalize;
    transition: .2s all ease-in-out;
    margin-bottom: 20px;
    text-decoration: none;
}
.mybtn img {
    width: auto;
    height: 14px;
    margin-left: 20px;
}
.mybtn:hover {
    background: #EF882E;
    text-decoration: unset !important;
    cursor: pointer;
    color: white;
}

.mybtn.mybtn-primary {
    background: #3C4043;
    border: 1px solid #3C4043;
    text-decoration: none !important;
}
.mybtn.mybtn-primary:hover {
    background: #1F1E1D;
    text-decoration: none !important;
    color: white;
}
.mybtn.mybtn-primary-solid {
    background: #ffff;
    border: 1px solid #ffff;
    text-decoration: none !important;
    color:#3C4043;
}
.mybtn.mybtn-primary-solid:hover {
    background: #ffff;
    text-decoration: none !important;
    color: #3C4043;
    border: 1px solid #3C4043;
}
.mybtn.mybtn-secondary {
    background: white;
    text-decoration: none !important;
}

.mybtn.mybtn-secondary:hover  {
    border: 2px solid #3C4043;
    text-decoration: none !important;
    color:#3C4043 ;
}

.mybtn.mybtn-transparent {
background: transparent;
border: 2px solid #F8933C;
text-decoration: none !important;
color: #F8933C;
}
.mybtn.mybtn-transparent:hover {
    background: transparent;
    border: 2px solid #EF882E;
    text-decoration: none !important;
    color: #EF882E;
}
.mybtn.mybtn-primary-transparent {
    background: transparent;
    border: 2px solid #3C4043;
    color:#3C4043;
    text-decoration: none !important;
    font-weight: 600;
}
.mybtn.mybtn-primary-transparent:hover {
    background: rgba(0, 0, 0, 0.05);
    border: 2px solid #1F1E1D;
    text-decoration: none !important;
    color:#1F1E1D;
}
.mybtn.mybtn-secondary-transparent {
    background: transparent;
    border: 2px solid white;
    color: white !important;
}
.mybtn-secondary-transparent:hover  {
    background: rgba(0, 0, 0, 0.205);
    border: 2px solid white;
    text-decoration: none !important;
    color: white !important;
}

/* Mobile */
@media (max-width: 480px) {
  .mybtn {
      height: 56px;
  }
}